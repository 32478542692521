<template>
  <div class="company-card tw-rounded tw-relative tw-px-4 tw-py-3">
    <div class="tw-flex tw-justify-between">
      <div>
        <div
          v-text="name"
          class="tw-uppercase tw-font-medium"
          data-test="name"
        />
        <p
          v-text="address.format('streetName postalCode city')"
          data-test="address"
        />
      </div>
      <img
        src="@/assets/img/icons/building.svg"
        alt=""
        width="20"
        height="25"
        class="tw-mt-1 tw-ml-2 tw-select-none"
        data-test="icon"
      >
    </div>
    <div class="tw-flex tw-justify-between tw-items-center">
      <div
        v-if="address.countryName"
        v-text="address.countryName"
        data-test="country"
      />
      <UiLink
        v-text="$options.filters.capitalize($t('edit'))"
        v-matomo="{
          click: { category: 'Register', action: 'Clicked', name: 'Confirmation Member Area' }
        }"
        href="#"
        aria-role="button"
        standalone
        data-test="edit"
        @click.prevent="$emit('edit')"
      />
    </div>
  </div>
</template>

<script>
  import Address from '@/models/Address'

  /**
   * @module component - CompanyCard
   * @param {string} name
   * @param {import('@/models/Address').default} address
   */
  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      address: {
        type: Address,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
.company-card {
  background-color: $light-gray;
}
.company-card::after {
  --tw-bg-opacity: 1;
  background-color: rgba(150, 191, 49, var(--tw-bg-opacity));
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0.25rem;
  content: '';
}
</style>
