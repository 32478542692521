var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-account-credentials tw-flex tw-flex-col tw-flex-1"},[_c('div',{staticClass:"register-account-credentials__content tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-mb-20 md:tw-mb-0"},[_c('div',{staticClass:"register-account-credentials__wrapper tw-mx-auto tw-py-4 md:tw-pt-24 md:tw-pb-12"},[_c('register-header',{staticClass:"tw-mb-10",attrs:{"title":_vm.$t('app.titles.register.account'),"steps":_vm.steps}}),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{attrs:{"disabled":_vm.$wait.is('creating account')},on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('ValidationProvider',{ref:"email-provider",attrs:{"name":_vm.$t('app.fields.email'),"rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4",attrs:{"id":"email","label":_vm.$t('app.labels.email'),"error":invalid && validated || errors && errors.length > 0,"hint":errors[0],"data-vv-as":_vm.$t('app.fields.email'),"disabled":_vm.$wait.is('creating account'),"type":"email","name":"email","autocomplete":"email","required":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}])}),_c('ValidationProvider',{ref:"password-provider",attrs:{"vid":"password","name":_vm.$t('app.fields.password'),"rules":"required|min:8","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-w-full tw-mb-4",attrs:{"id":"password","name":"password","type":"password","error":invalid && validated || errors && errors.length > 0,"hint":errors[0],"label":_vm._f("capitalize")(_vm.$t('app.labels.password')),"disabled":_vm.$wait.is('creating account'),"autocomplete":"new-password","required":""},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}])}),_c('ValidationProvider',{ref:"password-confirmation-provider",attrs:{"name":_vm.$t('app.fields.password'),"rules":"required|min:8|confirmed:password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-w-full tw-mb-4",attrs:{"id":"password-confirmation","name":"password-confirmation","type":"password","error":invalid && validated || errors && errors.length > 0,"hint":errors[0],"label":_vm._f("capitalize")(_vm.$t('app.labels.password_confirmation')),"disabled":_vm.$wait.is('creating account'),"autocomplete":"new-password","required":""},model:{value:(_vm.formData.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.formData, "passwordConfirmation", $$v)},expression:"formData.passwordConfirmation"}})]}}])}),_c('p',{staticClass:"password__form__password-explanation tw-mb-6 tw-italic"},[_vm._v(" "+_vm._s(_vm.$t('app.password.paragraphs.password_constraint'))+" ")]),_c('ValidationProvider',{ref:"tos-provider",attrs:{"name":"tos","rules":"required|length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-checkbox',{staticClass:"tw-text-secondary-text tw-mt-4",attrs:{"disabled":_vm.$wait.is('creating account'),"state":invalid && validated ? false : null,"value":"true","required":""},model:{value:(_vm.formData.tos),callback:function ($$v) {_vm.$set(_vm.formData, "tos", $$v)},expression:"formData.tos"}},[_c('div',{staticClass:"tw-inline tw--mt-1"},[_c('i18n',{attrs:{"path":"app.paragraphs.register.tos","tag":"span"},scopedSlots:_vm._u([{key:"tos",fn:function(){return [_c('UiLink',{attrs:{"href":_vm.tos,"target":"_blank","rel":"noopener"},domProps:{"textContent":_vm._s(_vm.$t('app.paragraphs.register.tos_label'))}})]},proxy:true}],null,true)})],1)]),(invalid && validated)?_c('div',{staticClass:"tw-text-red-500 tw-pl-6 tw-mt-1 tw-text-xs",domProps:{"textContent":_vm._s(_vm.$t('validator.required'))}}):_vm._e()]}}])}),_c('ValidationProvider',{ref:"pro-provider",attrs:{"name":"pro","rules":"required|length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-checkbox',{staticClass:"tw-text-secondary-text tw-mt-4",attrs:{"disabled":_vm.$wait.is('creating account'),"state":invalid && validated ? false : null,"value":"true","required":""},model:{value:(_vm.formData.pro),callback:function ($$v) {_vm.$set(_vm.formData, "pro", $$v)},expression:"formData.pro"}},[_c('div',{staticClass:"tw-inline tw--mt-1"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('app.paragraphs.register.pro'))}})])]),(invalid && validated)?_c('div',{staticClass:"tw-text-red-500 tw-pl-6 tw-mt-1 tw-text-xs",domProps:{"textContent":_vm._s(_vm.$t('validator.required'))}}):_vm._e()]}}])})],1)])],1)]),_c('register-navigation',{attrs:{"submit-disabled":_vm.$wait.is('creating account')},on:{"back":function($event){return _vm.$router.go(-1)},"submit":_vm.submitted}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }