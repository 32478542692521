import usePhoneCodeCountries from '@/composables/usePhoneCodeCountries'

export default class Address {
  constructor (streetName, city, postalCode, country) {
    this.streetName = streetName
    this.city = city
    this.postalCode = postalCode
    this.country = country
  }

  /**
   * @function isComplete
   * @returns {boolean}
   */
  get isComplete () {
    return !!this.streetName && !!this.city && !!this.postalCode && !!this.country
  }

  /**
   * Returns a formatted address according to the keywords
   * specified.
   * @function format
   * @param {string} format
   * @returns {string}
   */
  format (format) {
    let formatted = format
    const keywords = ['streetName', 'city', 'postalCode', 'country']
    keywords.forEach(keyword => {
      formatted = formatted.replace(keyword, this[keyword] || '')
    })

    return formatted.replace('  ', ' ')
  }

  /**
   * Returns the country name based on the country code
   * @function countryName
   * @returns {string}
   */
  get countryName () {
    const { countries } = usePhoneCodeCountries()
    const country = countries.find(country => country.iso2 === this.country)
    if (!country) throw new Error(`The country "${this.country}" does not exists in the countries list.`)

    return country.name
  }
}
